import React, { useState, useEffect } from "react";
import ProductsResult from "../productsResult/productsResult";
import ApiService from "../../actions/apiService";
import { Spinner, NavDropdown } from "react-bootstrap";
import Pagination from "../pagination/pagination";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import FiltersComposer from "../FiltersComposer/FiltersComposer";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import { ReactComponent as FilterSVG } from "./../../img/icons/filter.svg";
import { ReactComponent as SearchSVG } from "./../../img/icons/search.svg";
import categories from "../../helpers/categories";
import ReactGA from 'react-ga4'
import useDocumentTitle from "../../helpers/useDocumentTitle";
import { MAP_CATEGORY_TO_G4 } from "../../helpers/mappers";
import download from "downloadjs";

const ProductSearch = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let initFilter = localStorage.getItem("filter");
  initFilter = initFilter ? initFilter.split("%%%") : ["", "", "", ""];
  //console.log("initFilter", initFilter);
  const changePath = (location) => {
    history.push(location);
  };

  useDocumentTitle(`Ricerca - ${t(props.productType)}`)

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  console.log("params", params);
  const brandCodes = [
    { key: "CAL", label: 'CALLIGARIS' },
    { key: "CNB", label: 'CONNUBIA' }
  ]
  const quantityPlaceholder = t('Min. Qty.');
  const quantities =  [{
    key: null,
    label: t(quantityPlaceholder),
    disabled: true
  }].concat(
      ...(Array.from(Array(12).keys()).map((q)=>{
    return {
      key: q*2,
      label: (q === 0 ? t('all'): ''+q*2),
      disabled: false
    }}))
);
  const productType = props.productType;
  const searchQueryParam = params.search;
  const categoryQueryParam =  localStorage.getItem("category") || params.category || "all";
  const brandCodeQueryparam = params.brandCode || localStorage.getItem("brandCode") || "CAL";
  const [search, setSearch] = React.useState(searchQueryParam || localStorage.getItem("textSearch") || '');
  const [category, setCategory] = useState(categoryQueryParam);
  const [brandCode, setBrandCode] = useState(brandCodes.find(bc => bc.key === brandCodeQueryparam));
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalCount, setTotalCount] = useState(10);
  const [filters, setFilters] = useState(initFilter);
  const [length, setLength] = useState([0, 400]);
  const [depth, setDepth] = useState([0, 400]);

  const initQuantityValue = localStorage.getItem("minimumQuantity") || t(quantityPlaceholder);
  const initQuantity = quantities.find(q=>q.label === ''+initQuantityValue);
  const [quantity, setQuantity] = useState(initQuantity);
  const [sortBy, setSortBy] = useState({
    key: "Alphabetic",
    label: t("Alphabetic"),
  });
  const [isMTO, setIsMTO] = useState(false);
  const [quickShip, setQuickShip] = useState(false);

  const setNewFilters = function (newFilter) {
    setFilters(newFilter);
    localStorage.setItem("filter", newFilter.join("%%%"));
  };

  const sortByOptions = [
    { key: "Alphabetic", label: t("Alphabetic") },
    { key: "Code", label: t("Code") },
    { key: "Price", label: t("Price") },
  ];

  function getQueryParams(noPage) {
    //When api will be ok
    let query = {};
    if (search && search.length >= 1) {
      query["textValue"] = search;
    }
    if (category && category.toLowerCase() !== "all") {
      query["category"] = category;
    }
    if (category && (category === "tappeti" || category === "tavoli")) {
      query["minLength"] = length[0];
      query["maxLength"] = length[1];
      query["minDepth"] = depth[0];
      query["maxDepth"] = depth[1];
    }
    if (currentPage && !noPage) {
      query["page"] = currentPage;
    } else if(!noPage){
      query["page"] = 1;
    }
    if (pageSize && !noPage) {
      query["pageSize"] = pageSize;
    }
    if (sortBy) {
      query["sortBy"] = sortBy.key;
      query["direction"] = "Ascending";
    }
    if (isMTO) {
      query["isMTO"] = isMTO;
    }

    if (quickShip) {
      query["quickShip"] = quickShip;
    }

    if (filters.length > 0) {
      console.log("filters", filters);
      query["filter"] = filters
          .filter((flt) => {
            return ((props.productType === "configured" || props.productType === "outlet") &&
                    (!flt.startsWith(";;") || category === 'oggettistica')
                    && flt !== "") ||
                (props.productType === "configurable" && flt !== ";;" && flt !== "");
          })
          .join("|");
      if (!query["filter"]) {
        delete query["filter"];
      }
    }

    if (brandCode) {
      query["brandCode"] = brandCode.key;
    }
    //quantity
    query["minQuantity"] = 0;
    let onlyProductAvailableStorage = localStorage.getItem("onlyProductAvailable") === "true" || false;

    //if not set set as true
    if (localStorage.getItem("onlyProductAvailable") === null || localStorage.getItem("onlyProductAvailable") === "null"){
      onlyProductAvailableStorage = true;
    }

    if (onlyProductAvailableStorage) {
      query["minQuantity"] = 1;
    }
    if (quantity && quantity.key !== null) {
      query["minQuantity"] = quantity.key;
    }
    return query;
  }

  function getSearchResource(query, exportXSLX) {
    let suffix = "/search";
    if (exportXSLX){
      suffix = "/export";
    }
    let resource;
    switch (props.productType) {
      case "outlet":
        query["productGroup"] = "outlet";
        resource = "/products";
        break;
      case "configured":
        resource = "/products";
        break;
      case "configurable":
        resource = "/ditre";
        break;
    }
    resource += suffix;
    return resource;
  }

  const searchByCodice = React.useCallback(
    async (newSearch) => {
      console.log("searchByCodice filters", filters);
      if (newSearch) {
        setCurrentPage(1);
      }
      setLoading(true);
      let query = getQueryParams();

      try {
        let data;
        try {
          let resource = getSearchResource(query);
          data = await ApiService.get(resource, query);
        } catch (err) {
          data = { size: 0, products: [] };
        }
        setProducts(
          data.products.map((product, index) => {
            let imgLink = `https://www.calligaris.com/media-prods/436x320/${product.parentVersion || product.productCode}.jpg`;
            if (productType === 'configured') {
              imgLink = `https://www.calligaris.com/media/configsHires/${product.productCode}.png`;
            } else if(productType === 'outlet'){
              let parentVersion = product.parentVersion.replaceAll(" ", "-");
              imgLink = `https://www.calligaris.com/media/configsHires/${parentVersion}.png`;
            }

            return {
              id: product.id || index + 1,
              productType: productType,
              title: product.parentDescription || product.description,
              description1: product.productDescription1,
              description2: product.productDescription2,
              description3: product.productDescription3,
              price: product.price ? Number(product.price.Importo) : null,
              tax: product.tax ? Number(product.tax.Importo) : null,
              currency: product.price ? product.price.Valuta : null,
              codice: product.productCode,
              img: imgLink,
              product,
              availability: product.availability
            };
          })
        );
        setTotalCount(data.size);
        setLoading(false);
        if(brandCode && brandCode.key) {
          localStorage.setItem("brandCode", brandCode.key);
        }
        if(quantity && quantity.key){
        localStorage.setItem("minimumQuantity", quantity.key);
        }
        localStorage.setItem("textSearch", search);
      } catch (err) {
        console.log(err);
      }
    },
    [currentPage, sortBy, category, brandCode.key, filters, search, depth, length, isMTO, quickShip, quantity]
  );

  const searchByCodiceDebounced = AwesomeDebouncePromise(searchByCodice, 300);

  const setLengthDebounced = AwesomeDebouncePromise((minMaxArr) => {
    ReactGA.event(`${MAP_CATEGORY_TO_G4[category]}lng_${minMaxArr[0]}_${minMaxArr[1]}`)
    setLength(minMaxArr)
  }, 300);

  const setDepthDebounced = AwesomeDebouncePromise((minMaxArr) => {
    ReactGA.event(`${MAP_CATEGORY_TO_G4[category]}dpt_${minMaxArr[0]}_${minMaxArr[1]}`)
    setDepth(minMaxArr)
  }, 300);


  React.useEffect(async () => {
    await searchByCodice();
  }, [sortBy, category, filters, brandCode.key, currentPage, depth, length, isMTO, quickShip, quantity]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [category, brandCode.key, quantity]);

  const handleSortBy = function (key) {
    let sort = sortByOptions.find((option) => option.key === key);
    setSortBy(sort);
  };

  /* Sticky filters toggle button */
  const [filtersToggleTop, setFiltersToggleTop] = useState(undefined);

  useEffect(() => {
    const filtersToggle = document
      .querySelector(".filters-toggle")
      .getBoundingClientRect();
    setFiltersToggleTop(filtersToggle.top);
  }, []);

  useEffect(() => {
    if (!filtersToggleTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [filtersToggleTop]);

  const isSticky = (e) => {
    const filtersToggle = document.querySelector(".filters-toggle");
    const scrollTop = window.scrollY;
    if (filtersToggleTop <= scrollTop + 100) {
      filtersToggle.classList.add("is-sticky");
    } else {
      filtersToggle.classList.remove("is-sticky");
    }
  };

  const exportXSLX = async function () {
    let query = getQueryParams(true);
    let resource = getSearchResource(query, true);
    const blob = await ApiService.getFile(resource, query);
    let lng = localStorage.getItem("language");
    lng = lng.replace("_","-");
    let datetime = new Date().toLocaleString(lng, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
        .replaceAll(", ", " ").replaceAll("/","-").replaceAll(":","");
    download(blob,t("xslFileName") + datetime);
    const userInfo = sessionStorage.getItem("user")
    if (userInfo !== null) {
      const username = JSON.parse(userInfo).username
      ReactGA.event(`${username}_export_product_search`)
    } else {
      ReactGA.event(`export_product_search`)
    }
  }


  const [showFilters, setShowFilters] = useState(false);

  return (
    <div className="main product-search">
      <div className="container">
        <div className="page-header">
          <ul className="breadcrumbs">
            <li onClick={() => changePath("/")}>{t("Home")}</li>
            {props.productType === "outlet" && <li onClick={() => changePath("/outlet")}>{t("Outlet")}</li>}
            {props.productType === "configured" && <li onClick={() => changePath("/configured")}>{t("Configured")}</li>}
            {props.productType === "configurable" && <li onClick={() => changePath("/configurable")}>{t("Configurable")}</li>}
            {category != 'all' && <li className={category ? "active" : ""}>{t(category)}</li>}
          </ul>
          {category !== "all" && <h1 className="title">{t(category)}</h1>}
          <div className="search-form">
            <div>
              <form className={`form-${props.productType}`} onSubmit={(e) => e.preventDefault()}>
                <div className="search-input">
                  <input
                    type="text"
                    placeholder={t("Search product") + "..."}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    onClick={() => {
                      searchByCodice(true)
                      ReactGA.event(`search_${search}`)
                    }
                    }
                    className="only-icon"
                  >
                    <span className="label">{t("Search")}</span>
                    <SearchSVG />
                  </button>
                </div>

                <div className="category-input">
                  <NavDropdown
                    id="category-button"
                    title={t(category)}
                    onSelect={(cat) => {
                      setNewFilters(["", "", "", ""]);
                      setCategory(cat);
                    }
                  }
                  >
                    {categories[props.productType].map((cat) => {
                      return (
                        <NavDropdown.Item
                          eventKey={cat.key}
                          active={category === cat.key}
                          key={cat.key}
                        >
                          {t(cat.value)}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </div>

                { (props.productType === "configured" || props.productType === "outlet" ) && (<div className="brand-code-input">
                  <NavDropdown
                    id="brand-code-button"
                    title={t(brandCode.label)}
                    onSelect={(brandCode, component) => { setBrandCode({ key: brandCode, label: component.target.text }) }}
                  >
                    {brandCodes.map(bc => {
                      return (<NavDropdown.Item
                        eventKey={bc.key}
                        active={bc.key === brandCode.key}
                        key={bc.key}
                      >
                        {bc.label}
                      </NavDropdown.Item>);
                    })}
                  </NavDropdown>
                </div>)}
                <div className="quantity-input float-end">
                  <NavDropdown
                      id="quantity-input"
                      title ={quantity.label}
                      onSelect={(q, component) => { setQuantity({ key: q, label: component.target.text }) }}
                  >
                    {quantities.map(qu => {
                      return (<NavDropdown.Item
                          disabled={!!qu.disabled}
                          eventKey={qu.key}
                          active={qu.key === quantity.key}
                          key={qu.key}
                      >
                        {qu.label}
                      </NavDropdown.Item>);
                    })}
                  </NavDropdown>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="filters-toggle-wrapper">
          <button
            className="button filters-toggle"
            onClick={() => setShowFilters(true)}
          >
            <span className="label">{t("Filter")}</span>
            <FilterSVG />
          </button>
        </div>

        <div className={`filters ${showFilters ? "show" : ""}`}>
          <div className="filters-header">
            <button
              className="close"
              onClick={() => setShowFilters(false)}
            ></button>
            <span>{t("Filter")}</span>
          </div>

          <div className="filters-input">
            <FiltersComposer
              productType={productType}
              category={category}
              filtersSetter={setNewFilters}
              filters={filters}
              lengthSetter={(min, max) => {
                setLengthDebounced([min, max]);
              }}
              depthSetter={(min, max) => {
                setDepthDebounced([min, max]);
              }}
              isMTOSetter={setIsMTO}
              quickShipSetter={setQuickShip}
            ></FiltersComposer>
          </div>

          <div className="filters-footer">
            <button className="button" onClick={() => setShowFilters(false)}>
              {t("View products")}
            </button>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("Loading")}...</span>
          </Spinner>
        </div>
      )}
      {!loading && (
        <>
          <div className="summary">
            <div className="container">
              <div className="summary-wrapper">
                <div className="total">
                  {totalCount} <span>{t("found products")}</span>
                  &nbsp;<a class="export-button" onClick={exportXSLX}>{t('export XLSX')}</a>
                </div>
                <div className="sort">
                  <span>{t("Sort by")}</span>
                  <NavDropdown
                    className={"ms-auto"}
                    style={{ width: "fit-content" }}
                    id="order-button"
                    title={t(sortBy.key)}
                    onSelect={handleSortBy}
                  >
                    {sortByOptions.map((option) => {
                      return (
                        <NavDropdown.Item
                          eventKey={option.key}
                          active={sortBy.key === option.key}
                        >
                          {t(option.key)}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            {totalCount > 0 ? (
              <ProductsResult products={products} category={category} />
            ) : (
              <div className="products-not-found">
                {t("No results found")} :(
              </div>
            )}
            {totalCount > 0 && <Pagination
              itemsCount={totalCount}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
            />}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductSearch;
